<template>
  <div>
    <div v-if="debug" ref="rect"
      :style="{
      width: String(width) + 'px',
      height: String(height) + 'px',
    }">
      <v-img class="hotspot-bg hotspot-btn" src="/grid.png" :style="{
      width: String(width) + 'px',
      height: String(height) + 'px',
      opacity: 0.5,
    }" tab-index="-1" alt="" id="hotspot-img" >
      </v-img>
    </div>
    <div v-if="!debug" ref="icon" class="icon-div" v-show="validIcon && !invisible">
      <v-container fill-height class="hotspot-hint fluid pa-0" v-show="true || (showTooltip && !debug)">
        <v-row class="text-center pa-0 ma-0" align="center" justify="center">
          <v-scale-transition origin="center center">
            <ConcentricCircles v-show="!showTooltip && !isCentered && !invisible" class="hotspot-circle" />
          </v-scale-transition>

          <!-- this is the small icon - might be the only one used if title has no text-->
          <v-scale-transition origin="center center">
            <v-card v-show="(!showTooltip && !isCentered && !invisible) || title === ''" dark color="secondary" rounded="pill" :class="
                !showTooltip
                  ? 'pa-0 hotspot-title hotspot-icon'
                  : 'pa-1 hotspot-title hotspot-icon'
              ">
              <v-icon class="pa-3">{{ icon }}</v-icon>
            </v-card>
          </v-scale-transition>

          <!-- if we have text, switch to this bigger icon on showTooltip = true -->
          <v-scale-transition origin="center center" v-if="title !== ''">
            <v-card :style="cardStyles" v-show="invisible ? false : showTooltip || isCentered" dark color="secondary" rounded="pill" :class="
                showTooltip
                  ? 'pa-1 hotspot-title hotspot-icon'
                  : 'pa-0 hotspot-title hotspot-icon'
              ">
              <v-card-subtitle class="pa-2 ml-2 mr-2 white--text">
                <v-icon class="pr-2">{{ icon }}</v-icon>
                {{ title }}
              </v-card-subtitle>
            </v-card>
          </v-scale-transition>
        </v-row>
      </v-container>
    </div>
    <v-scale-transition v-if="!debug" origin="center center">
      <div :data-testid="'rectHotspot' + index" id="rectHotspot" ref="rect" class="hotspot-bg hotspot-btn" :style="{
          width: hotspotSizeString.width,
          height: hotspotSizeString.height,
        }" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false" @mousemove="showTooltip = true" alt="">
        <div>
          <v-btn text dark :style="{
              width: hotspotSizeString.width,
              height: hotspotSizeString.height,
            }" :aria-label="ariaLabel" :disabled="!clickable" @click="clicked" @mousedown="mouseDown"
            @mousemove="mouseMove" @focus="onFocus" id="hotspot-img" :data-testid="'hotspotBtn' + index">
            <div tabindex="-1" :class="transparent ? 'img-no-background' : 'img-background'">
              <v-img v-if="hasThumbnail" :style="{
                  width: hotspotSizeString.width,
                  height: hotspotSizeString.height,
                }" :src="thumbnail.file" contain tabindex="-1" alt="">
              </v-img>
            </div>
            <slot />
          </v-btn>
          <v-fade-transition>
            <div class="hotspot-overlay" v-show="showTooltip && !debug && clickable">
              <v-container fill-height fluid class="background">
                <v-row align="center" justify="center">
                  <v-col>
                    <!-- <div class="text-center">
                      <h1 class="white--text">{{ title }}</h1>
                    </div> -->
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-fade-transition>
        </div>
      </div>
    </v-scale-transition>


  </div>
</template>

<script>
import ConcentricCircles from "./ConcentricCircles.vue";

export default {
  name: "MarzipanoRectHotspot",
  props: [
    "index",
    "invisible",
    "yaw",
    "pitch",
    "width",
    "height",
    "rotX",
    "rotY",
    "rotZ",
    "scene",
    "thumbnail",
    "title",
    "icon",
    "debug",
    "clickable",
    "transparent",
    "activeSceneIndex",
    "arialabel",
    "lightboxActive",
    "movementSpeed",
    "isMoving",
  ],
  data: () => ({
    showTooltip: false,
    hasMoved: false,
    hasClicked: false,
    isCentered: false,
    marzipanoScene: null,
    container: null,
  }),
  components: { ConcentricCircles },

  mounted: function () {
    // this.scene.hotspotContainer().listHotspots()
    //   .forEach((item) =>
    //     this.scene.destroyHotspot(item)
    //   );
    // console.log(this.scene, "data from recthotspot");
    // Get the hotspot container for scene.
    this.marzipanoScene = this.scene;
    this.container = this.marzipanoScene.hotspotContainer();

    // Create hotspot with different sources.
    if (this.$refs.rect !== undefined) {
      this.hotspot = this.container.createHotspot(
        this.$refs.rect,
        { yaw: this.yaw, pitch: this.pitch },
        {
          perspective: this.getPerspectiveTransform(),
        }
      );
    }

    // Create hotspot with different sources.
    if (this.$refs.icon !== undefined) {
      this.hotspotIcon = this.container.createHotspot(this.$refs.icon, {
        yaw: this.yaw,
        pitch: this.pitch,
      });
    }

    let self = this;

    this.marzipanoScene.addEventListener("viewChange", this.viewChanged);
    
  },
  beforeDestroy() {
    this.marzipanoScene.removeEventListener("viewChange", this.viewChanged);
    if (this.hotspot) {
      this.container.destroyHotspot(this.hotspot);
    }
    if (this.hotspotIcon) {
      this.container.destroyHotspot(this.hotspotIcon);
    }
  },
  methods: {
    //Accessibility Function that will enable user to navigate using the TAB Key

    onFocus(e) {
      if (e.target.matches("#hotspot-img") && !this.isMoving) {
        this.$emit("focus");

        // Move the view to look at this particular hotspot
        const hotspotDirection = {
          yaw: this.yaw,
          pitch: this.pitch,
          fov: (60 * Math.PI) / 60,
        };
        const options = {
          transitionDuration: this.movementSpeed,
        };

        // Look to the hotspot.
        this.marzipanoScene.lookTo(hotspotDirection, options);
      }
    },

    getPerspectiveTransform() {
      return {
        radius: 1000,
        extraTransforms:
          "rotateY(" +
          this.xRotation +
          "deg) rotateX(" +
          this.yRotation +
          "deg) rotateZ(" +
          this.zRotation +
          "deg)",
      };
    },

    updateHotspot() {
      if (this.hotspot === undefined) return;
      this.hotspot.setPosition({ yaw: this.yaw, pitch: this.pitch });
      this.hotspot.setPerspective(this.getPerspectiveTransform());
    },

    clicked() {
      if (!this.hasMoved) {
        this.$emit("click");
        this.hasClicked = false;
        this.hasMoved = false;
      }
    },

    mouseDown() {
      this.hasClicked = true;
      this.hasMoved = false;
    },

    mouseMove() {
      if (this.hasClicked === true) {
        this.hasMoved = true;
      }
    },

    viewChanged() {
      if (!this.marzipanoScene) {
        return;
      }
      let difference1 = Math.abs(
        this.marzipanoScene.view().yaw() - this.hotspot.position().yaw
      );
      // This tests for angles that are close to the wrap around point
      // Eg Angle1 = -3.1 and Angle2 = 3.1 - these angles are technically only 0.083185307 radians apart
      let difference2 = Math.PI * 2.0 - difference1;
      let threshold = 0.4;
      if (difference1 < threshold || difference2 < threshold)
        this.isCentered = true;
      else this.isCentered = false;
    },
  },
  computed: {
    cardStyles() {
      const styles = {};

      // Check if minExpandedWidth is greater than 0, then set the min-width
      if (this.$store.getters["wagtail/getMinHotspotSize"] > 0) {
        styles.minWidth = `${this.$store.getters["wagtail/getMinHotspotSize"]}px`;
      }

      // Check if maxExpandedWidth is greater than 0, then set the max-width
      if (this.$store.getters["wagtail/getMaxHotspotSize"] > 0) {
        styles.maxWidth = `${this.$store.getters["wagtail/getMaxHotspotSize"]}px`;
      }

      return styles;
    },
    hasThumbnail() {
      if (this.thumbnail === null || this.thumbnail === undefined) return false;
      return true;
    },
    elHeight() {
      return this.height;
    },
    ariaLabel() {
      return this.arialabel;
    },
    xRotation() {
      if (this.rotX === undefined) return 0.0;
      return this.rotX;
    },
    yRotation() {
      if (this.rotY === undefined) return 0.0;
      return this.rotY;
    },
    zRotation() {
      if (this.rotZ === undefined) return 0.0;
      return this.rotZ;
    },
    hotspotSize() {
      // Resize the hotspot area, to be of the same ratio as the source image
      // but no larger than our max dimensions from the hotspot canvas json

      let hotspotWidth = parseFloat(this.width.replace("px"));
      let hotspotHeight = parseFloat(this.height.replace("px"));

      if (this.hasThumbnail === false || this.debug === true)
        return { width: hotspotWidth, height: hotspotHeight };

      let hotspot_canvas_ratio = hotspotWidth / hotspotHeight;
      let thumbnail_ratio = this.thumbnail.width / this.thumbnail.height;
      let thumbnail_scale = 1.0;

      if (thumbnail_ratio > hotspot_canvas_ratio) {
        //Image has a wider aspect than canvas, so we need to clamp to canvas width and maintain aspect
        thumbnail_scale = hotspotWidth / this.thumbnail.width;
      } else {
        //Image has a taller aspect than canvas, so we need to clamp to canvas height and maintain aspect
        thumbnail_scale = hotspotHeight / this.thumbnail.height;
      }
      return {
        width: this.thumbnail.width * thumbnail_scale,
        height: this.thumbnail.height * thumbnail_scale,
      };
      //return {width: hotspotWidth, height: hotspotHeight}
    },
    hotspotSizeString() {
      let width = this.hotspotSize.width.toString() + "px";
      let height = this.hotspotSize.height.toString() + "px";
      return { width: width, height: height };
    },
    hotspotWidth() {
      return "512px";
    },
    hotspotHeight() {
      return "100px";
    },
    changeData() {
      let data = {
        yaw: this.yaw,
        pitch: this.pitch,
        width: this.width,
        height: this.height,
        rotX: this.rotX,
        rotY: this.rotY,
        rotZ: this.rotZ,
      };
      return data;
    },
    validIcon() {
      if (this.icon !== undefined && this.icon !== null && !this.invisible)
        return true;
      return false;
    },
  },
  watch: {
    pitch() {
      this.updateHotspot();
    },
    yaw() {
      this.updateHotspot();
    },
    rotX() {
      this.updateHotspot();
    },
    rotY() {
      this.updateHotspot();
    },
    rotZ() {
      this.updateHotspot();
    },
  },
};
</script>

<style scoped>
.hotspot-bg {
  /* background-image: url("/grid.png"); */
  /* background-color: grey; */
  background-color: RGBA(0, 0, 0, 0);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
}

.hotspot-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  /* outline-width: 4px; */
  /* outline-style: solid; */
  /* outline-color: white; */
  box-shadow: 0px 0px 50px white;
  opacity: 0.9;
  pointer-events: none;
}

.hotspot-btn {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hotspot-icon {
  position: absolute;
  /* margin-left: -20px;
  margin-top: -20px; */
}

.hotspot-title {
  transition: 0.15s padding ease-out;
}

.hotspot-hint {
  position: absolute;
  width: 400px;
  height: 100px;
  /* bottom: 40px; */
  margin-left: -200px;
  margin-top: -50px;

  pointer-events: none;
}

.hotspot-circle {
  position: absolute;
  transform: scale(1);
}

.icon-div {
  pointer-events: none;
}

.debug-rect {
  background: red;
}

.down-arrow {
  position: absolute;
  bottom: 0px;
  width: 10px;
  height: 2px;
}

.img-background {
  background: white;
}

.img-background {
  background: transparent;
}

#hotspot-img:focus-visible {
  outline: 4px dashed darkorange;
}
</style>
